import './App.css';
import * as React from "react";
import * as FlexLayout from "flexlayout-react";

var json = {
  global: {tabEnableClose:false},
  borders:[
      {
        "type": "border",
        "location":"bottom",
        "size": 100,
        "children": [
            {
            "type": "tab",
            "name": "four",
            "component": "text"
            }
          ]
      },
      {
        "type": "border",
        "location":"left",
        "size": 100,
        "children": []
       }
  ],
  layout: {
      "type": "row",
      "weight": 100,
      "children": [
          {
              "type": "tabset",
              "weight": 50,
              "selected": 0,
              "children": [
                  {
                      "type": "tab",
                      "name": "One",
                      "component": "text"
                  }
              ]
          },
          {
              "type": "tabset",
              "weight": 50,
              "selected": 1,
              "children": [
                  {
                      "type": "tab",
                      "name": "Two",
                      "component": "text"
                  },
                  {
                    "type": "tab",
                    "id": "#f176d44d-9714-4f23-90b1-01773c616ad9",
                    "name": "Wikipedia",
                    "component": "multitype",
                    "config": {
                      "type": "url",
                      "data": "https://en.wikipedia.org/wiki/Main_Page"
                    }
                  }
              ],
              "active": true
          }
      ]
  }
};

class Main extends React.Component {

  constructor(props) {
    super(props);
    this.state = {model: FlexLayout.Model.fromJson(json)};
  }

  factory(node) {
      var component = node.getComponent(); 
      if (component === "text") {
          return (<div className="panel"><img src="zenstock_white.png" id="img_3b4af324" alt="" title="" width="500" height="100"/></div>);
      }
      else if (component === "multitype") {
        try {
            const config = node.getConfig();
            if (config.type === "url") {
                return <iframe title={node.getId()} src={config.data} style={{ display: "block", border: "none", boxSizing: "border-box" }} width="100%" height="100%" />;
            } else if (config.type === "html") {
                return (<div dangerouslySetInnerHTML={{ __html: config.data }} />);
            } else if (config.type === "text") {
                return (
                    <textarea style={{ position: "absolute", width: "100%", height: "100%", resize: "none", boxSizing: "border-box", border: "none" }}
                        defaultValue={config.data}
                    />);
            }
        } catch (e) {
            return (<div>{String(e)}</div>);
        }
      }
  }

  render() {
      return (
          <FlexLayout.Layout
              model={this.state.model}
              factory={this.factory.bind(this)}/>
      );
  }
}
// const root = ReactDOM.createRoot(document.getElementById("container"));
// root.render(<Main/>);


export default Main;
